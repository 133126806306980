@import '~normalize.css/normalize.css';
@import url('../node_modules/toastr/build/toastr.min.css');

body, html, #root {
  font-family: 'Trebuchet MS', sans-serif;
}

* {
  box-sizing: border-box;
}
